// IMPORTS
import * as THREE from "three";
import { randomValue } from "../helpers/methods";
import { settings } from "../helpers/settings";
import Wheel from "./Wheel";
// COMPONENT
const Truck = () => {
  // COLORS
  const {
    vehicle,
    other: {
      cab,
      chasis,
      window: { outter, inner },
    },
  } = settings.colors;
  // WINDSHIELD TEXTURE
  const windShieldTexture = () => {
    const canvas = document.createElement("canvas");
    canvas.width = 32;
    canvas.height = 32;
    const context = canvas.getContext("2d");
    context.fillStyle = outter;
    context.fillRect(0, 0, 32, 32);
    context.fillStyle = inner;
    context.fillRect(0, 5, 32, 10);
    return new THREE.CanvasTexture(canvas);
  };
  // WINDOW TEXTURE
  const windowTexture = () => {
    const canvas = document.createElement("canvas");
    canvas.width = 32;
    canvas.height = 32;
    const context = canvas.getContext("2d");
    context.fillStyle = outter;
    context.fillRect(0, 0, 32, 32);
    context.fillStyle = inner;
    context.fillRect(17, 5, 15, 10);
    return new THREE.CanvasTexture(canvas);
  };
  // GENERATE BODY
  const generateBody = () => {
    const body = new THREE.Mesh(
      new THREE.BoxBufferGeometry(100, 25, 5),
      new THREE.MeshLambertMaterial({ color: chasis })
    );
    body.position.z = 10;
    const trailer = new THREE.Mesh(
      new THREE.BoxBufferGeometry(75, 35, 40),
      new THREE.MeshLambertMaterial({ color: cab })
    );
    trailer.position.x = -15;
    trailer.position.z = 30;
    trailer.castShadow = true;
    trailer.receiveShadow = true;
    return { body, trailer };
  };
  // GENERATE CABIN
  const generateCabin = () => {
    const color = randomValue(vehicle);
    const truckFrontTexture = windShieldTexture();
    truckFrontTexture.center = new THREE.Vector2(0.5, 0.5);
    truckFrontTexture.rotation = Math.PI / 2;
    const truckLeftTexture = windowTexture();
    truckLeftTexture.flipY = false;
    const truckRightTexture = windowTexture();
    const cabin = new THREE.Mesh(new THREE.BoxBufferGeometry(25, 30, 30), [
      new THREE.MeshLambertMaterial({ color, map: truckFrontTexture }),
      new THREE.MeshLambertMaterial({ color }),
      new THREE.MeshLambertMaterial({ color, map: truckLeftTexture }),
      new THREE.MeshLambertMaterial({ color, map: truckRightTexture }),
      new THREE.MeshLambertMaterial({ color }),
      new THREE.MeshLambertMaterial({ color }),
    ]);
    cabin.position.x = 40;
    cabin.position.z = 20;
    cabin.castShadow = true;
    cabin.receiveShadow = true;
    return cabin;
  };
  // GENERATE WHEELS
  const generateWheels = () => {
    const frontWheel = Wheel();
    frontWheel.position.x = 38;
    const middleWheel = Wheel();
    middleWheel.position.x = 10;
    const backWheel = Wheel();
    backWheel.position.x = -30;
    return { frontWheel, backWheel, middleWheel };
  };
  // RENDER
  const truck = new THREE.Group();
  const { body, trailer } = generateBody();
  const { frontWheel, middleWheel, backWheel } = generateWheels();
  truck.add(body);
  truck.add(trailer);
  truck.add(generateCabin());
  truck.add(frontWheel);
  truck.add(middleWheel);
  truck.add(backWheel);
  return truck;
};
// EXPORT
export default Truck;
