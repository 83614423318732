// IMPORTS
import * as THREE from "three";
import { randomValue } from "../helpers/methods";
import { settings } from "../helpers/settings";
// COMPONENT
const Trees = () => {
  // STATE
  const { colors } = settings;
  const { arcCenter } = settings.track;
  // GENERATE TREE
  const generateTree = (position) => {
    const tree = new THREE.Group();
    const trunk = new THREE.Mesh(
      new THREE.BoxBufferGeometry(15, 15, 30),
      new THREE.MeshLambertMaterial({
        color: colors.tree.trunk,
      })
    );
    trunk.position.z = 10;
    trunk.castShadow = true;
    trunk.receiveShadow = true;
    trunk.matrixAutoUpdate = false;
    tree.add(trunk);
    const treeHeight = randomValue([45, 60, 75]);
    const crown = new THREE.Mesh(
      new THREE.SphereGeometry(treeHeight / 2, 30, 30),
      new THREE.MeshLambertMaterial({
        color: colors.tree.crown,
      })
    );
    crown.position.z = treeHeight / 2 + 30;
    crown.castShadow = true;
    crown.receiveShadow = false;
    tree.add(crown);
    position.x && (tree.position.x = position.x);
    position.y && (tree.position.y = position.y);
    return tree;
  };
  // RENDER
  return [
    generateTree({ x: arcCenter * 1.3, y: null }),
    generateTree({ x: arcCenter * 1.3, y: arcCenter * 1.9 }),
    generateTree({ x: arcCenter * 0.8, y: arcCenter * 2 }),
    generateTree({ x: arcCenter * 1.8, y: arcCenter * 2 }),
    generateTree({ x: -arcCenter * 1, y: arcCenter * 2 }),
    generateTree({ x: -arcCenter * 2, y: arcCenter * 1.8 }),
    generateTree({ x: arcCenter * 0.8, y: -arcCenter * 2 }),
    generateTree({ x: arcCenter * 1.8, y: -arcCenter * 2 }),
    generateTree({ x: -arcCenter * 1, y: -arcCenter * 2 }),
    generateTree({ x: -arcCenter * 2, y: -arcCenter * 1.8 }),
    generateTree({ x: arcCenter * 0.6, y: -arcCenter * 2.3 }),
    generateTree({ x: arcCenter * 1.5, y: -arcCenter * 2.4 }),
    generateTree({ x: -arcCenter * 0.7, y: -arcCenter * 2.4 }),
    generateTree({ x: -arcCenter * 1.5, y: -arcCenter * 1.8 }),
  ];
};
// EXPORT
export default Trees;
