// IMPORTS
import * as THREE from "three";
import { settings } from "../helpers/settings";
// COMPONENT
const Wheel = () => {
  const mesh = new THREE.Mesh(
    new THREE.BoxBufferGeometry(12, 33, 12),
    new THREE.MeshLambertMaterial({ color: settings.colors.other.wheel })
  );
  mesh.position.z = 6;
  mesh.castShadow = false;
  mesh.receiveShadow = false;
  return mesh;
};
// EXPORT
export default Wheel;
