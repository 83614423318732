// IMPORTS
import React, { useRef, useCallback, useEffect } from "react";
import * as THREE from "three";
import { randomSpeed, randomValue, getDistance } from "../helpers/methods";
import { settings } from "../helpers/settings";
import Car from "../components/Car";
import Track from "../components/Track";
import Trees from "../components/Trees";
import Truck from "../components/Truck";
import Menu from "../components/Menu";
// COMPONENT
const Game = ({ appRef }) => {
  // STATE
  let mount = useRef(null);
  const { appState, setAppState } = appRef;
  // UPDATE GAME STATE
  const updateGameState = useCallback(
    (gameState) => {
      setAppState((currentAppState) => {
        return {
          ...currentAppState,
          game: { ...currentAppState.game, ...gameState },
        };
      });
    },
    [setAppState]
  );
  // LIFE CYCLE
  useEffect(() => {
    // VARIABLES
    const { radius, arcCenter } = settings.track;
    const height = window.innerHeight;
    const width = window.innerWidth;
    let started = false;
    let paused = false;
    let over = false;
    let score = 0;
    const speed = 0.0017;
    const playerAngleInitial = Math.PI;
    let playerAngleMoved;
    let accelerate = false;
    let decelerate = false;
    let otherVehicles = [];
    let lastTimestamp;
    // LIGHTS
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6);
    directionalLight.position.set(100, -300, 300);
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.width = 1024;
    directionalLight.shadow.mapSize.height = 1024;
    directionalLight.shadow.camera.left = -400;
    directionalLight.shadow.camera.right = 350;
    directionalLight.shadow.camera.top = 400;
    directionalLight.shadow.camera.bottom = -300;
    directionalLight.shadow.camera.near = 100;
    directionalLight.shadow.camera.far = 800;
    // CAMERA
    const aspectRatio = width / height;
    const cameraWidth = 960;
    const cameraHeight = cameraWidth / aspectRatio;
    const camera = new THREE.OrthographicCamera(
      cameraWidth / -2,
      cameraWidth / 2,
      cameraHeight / 2,
      cameraHeight / -2,
      50,
      700
    );
    camera.position.set(0, -210, 300);
    camera.lookAt(0, 0, 0);
    // SCENE
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(settings.colors.track.lawn);
    const playerCar = Car(true);
    const track = Track({ height, width });
    scene.add(ambientLight);
    scene.add(directionalLight);
    scene.add(playerCar);
    scene.add(track.plane);
    scene.add(track.field);
    Trees().forEach((tree) => scene.add(tree));
    // RENDERER
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      powerPreference: "high-performance",
    });
    renderer.setSize(width, height);
    renderer.shadowMap.enabled = true;
    mount.current.appendChild(renderer.domElement);
    // MOVE PLAYER CAR
    const movePlayerCar = (timeDelta) => {
      const playerSpeed = accelerate
        ? speed * 2
        : decelerate
        ? speed * 0.5
        : speed;
      playerAngleMoved -= playerSpeed * timeDelta;
      const totalPlayerAngle = playerAngleInitial + playerAngleMoved;
      const playerX = Math.cos(totalPlayerAngle) * radius - arcCenter;
      const playerY = Math.sin(totalPlayerAngle) * radius;
      playerCar.position.x = playerX;
      playerCar.position.y = playerY;
      playerCar.rotation.z = totalPlayerAngle - Math.PI / 2;
    };
    // MOVE OTHER VEHICLES
    const moveOtherVehicles = (timeDelta) => {
      otherVehicles.forEach((vehicle) => {
        vehicle.clockwise
          ? (vehicle.angle -= speed * timeDelta * vehicle.speed)
          : (vehicle.angle += speed * timeDelta * vehicle.speed);
        const vehicleX = Math.cos(vehicle.angle) * radius + arcCenter;
        const vehicleY = Math.sin(vehicle.angle) * radius;
        const rotation =
          vehicle.angle + (vehicle.clockwise ? -Math.PI / 2 : Math.PI / 2);
        vehicle.mesh.position.x = vehicleX;
        vehicle.mesh.position.y = vehicleY;
        vehicle.mesh.rotation.z = rotation;
      });
    };
    // ADD VEHICLE
    const addVehicle = () => {
      const vehicleTypes = ["car", "truck"];
      const type = randomValue(vehicleTypes);
      const speed =
        type === "car"
          ? randomSpeed(1, 2)
          : type === "truck" && randomSpeed(0.6, 1.5);
      const clockwise = Math.random() >= 0.5;
      const angle = clockwise ? Math.PI / 2 : -Math.PI / 2;
      const mesh = type === "car" ? Car() : Truck();
      scene.add(mesh);
      otherVehicles.push({ mesh, type, speed, clockwise, angle });
    };
    // COLLISION DETECTION
    const collisionDetection = () => {
      const getHitZonePosition = (center, angle, clockwise, distance) => {
        const directionAngle = angle + clockwise ? -Math.PI / 2 : +Math.PI / 2;
        return {
          x: center.x + Math.cos(directionAngle) * distance,
          y: center.y + Math.sin(directionAngle) * distance,
        };
      };
      const playerHitZone1 = getHitZonePosition(
        playerCar.position,
        playerAngleInitial + playerAngleMoved,
        true,
        15
      );
      const playerHitZone2 = getHitZonePosition(
        playerCar.position,
        playerAngleInitial + playerAngleMoved,
        true,
        -15
      );
      const hit = otherVehicles.some((vehicle) => {
        if (vehicle.type === "car") {
          const vehicleHitZone1 = getHitZonePosition(
            vehicle.mesh.position,
            vehicle.angle,
            vehicle.clockwise,
            15
          );
          const vehicleHitZone2 = getHitZonePosition(
            vehicle.mesh.position,
            vehicle.angle,
            vehicle.clockwise,
            -15
          );
          return (
            getDistance(playerHitZone1, vehicleHitZone1) < 40 ||
            getDistance(playerHitZone1, vehicleHitZone2) < 40 ||
            getDistance(playerHitZone2, vehicleHitZone1) < 40
          );
        }
        if (vehicle.type === "truck") {
          const vehicleHitZone1 = getHitZonePosition(
            vehicle.mesh.position,
            vehicle.angle,
            vehicle.clockwise,
            35
          );
          const vehicleHitZone2 = getHitZonePosition(
            vehicle.mesh.position,
            vehicle.angle,
            vehicle.clockwise,
            0
          );
          const vehicleHitZone3 = getHitZonePosition(
            vehicle.mesh.position,
            vehicle.angle,
            vehicle.clockwise,
            -35
          );
          return (
            getDistance(playerHitZone1, vehicleHitZone1) < 40 ||
            getDistance(playerHitZone1, vehicleHitZone2) < 40 ||
            getDistance(playerHitZone1, vehicleHitZone3) < 40 ||
            getDistance(playerHitZone2, vehicleHitZone1) < 40
          );
        }
        return false;
      });
      if (hit) {
        renderer.setAnimationLoop(null);
        over = true;
        updateGameState({ over });
      }
    };
    // ANIMATION
    const animation = (timestamp) => {
      !lastTimestamp && (lastTimestamp = timestamp);
      const timeDelta = timestamp - lastTimestamp;
      const laps = Math.floor(Math.abs(playerAngleMoved) / (Math.PI * 2));
      laps !== score && updateGameState({ score: laps });
      otherVehicles.length < (laps + 1) / 5 && addVehicle();
      movePlayerCar(timeDelta);
      moveOtherVehicles(timeDelta);
      collisionDetection();
      renderer.render(scene, camera);
      lastTimestamp = timestamp;
    };
    // RESET
    const reset = () => {
      playerAngleMoved = 0;
      score = 0;
      updateGameState({ score });
      otherVehicles.forEach((vehicle) => scene.remove(vehicle.mesh));
      otherVehicles = [];
      lastTimestamp = undefined;
      movePlayerCar(0);
      renderer.render(scene, camera);
    };
    // HANDLE EVENTS
    const handleEvents = () => {
      window.addEventListener("resize", () => {
        const newAspectRatio = window.innerWidth / window.innerHeight;
        const adjustedCameraHeight = cameraWidth / newAspectRatio;
        camera.top = adjustedCameraHeight / 2;
        camera.bottom = adjustedCameraHeight / -2;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.render(scene, camera);
      });
      window.addEventListener("keydown", (event) => {
        event.key === "ArrowUp" && (accelerate = true);
        event.key === "ArrowDown" && (decelerate = true);
        if (event.key === "R" || event.key === "r") {
          if (over) {
            reset();
            renderer.setAnimationLoop(animation);
            started = true;
            over = false;
            updateGameState({ started, over });
          }
        }
        if (event.key === "Spacebar" || event.key === " ") {
          if (!started) {
            renderer.setAnimationLoop(animation);
            started = true;
            updateGameState({ started });
          } else if (!over) {
            if (paused) {
              renderer.setAnimationLoop(animation);
              paused = false;
              updateGameState({ paused });
            } else {
              renderer.setAnimationLoop((timestamp) => {
                lastTimestamp = timestamp;
                return null;
              });
              paused = true;
              updateGameState({ paused });
            }
          }
        }
      });
      window.addEventListener("keyup", (event) => {
        event.key === "ArrowUp" && (accelerate = false);
        event.key === "ArrowDown" && (decelerate = false);
      });
    };
    // INITIALIZE
    reset();
    handleEvents();
  }, [updateGameState]);
  // RENDER
  return (
    <div ref={mount} className="gameContainer">
      <Menu type="game" gameState={appState.game} />
    </div>
  );
};
// EXPORT
export default Game;
