// IMPORTS
import React from "react";
// COMPONENT
const Menu = ({ type, gameState }) => {
  // STATE
  const { started, paused, over, score } = gameState;
  // RENDER
  return type === "app" ? (
    <div className="menuContainer">
      {!started ? (
        <div className="menu">
          <p className="menuHeader">Traffic Run</p>
          <p className="menuText">- Use ⬆️ arrow key to accelerate</p>
          <p className="menuText">- Use ⬇️ arrow key to decelerate</p>
          <p className="menuText">- A new vehicle will spawn every 5 laps</p>
          <p className="menuTitle">Press Space to Start</p>
        </div>
      ) : paused ? (
        <div className="menu">
          <p className="menuHeader">Paused</p>
          <p className="menuTitle">Press Space to Resume</p>
        </div>
      ) : (
        over && (
          <div className="menu">
            <p className="menuHeader">Game Over</p>
            <p className="menuTitle">Press R to Restart</p>
          </div>
        )
      )}
    </div>
  ) : (
    type === "game" && (
      <div
        className="menu"
        style={{
          height: "10rem",
          top: "2rem",
          left: "2rem",
        }}
      >
        <p className="menuTitle">Laps: {score}</p>
        <p className="menuText">press space to pause</p>
      </div>
    )
  );
};
// EXPORT
export default Menu;
