// IMPORTS
import * as THREE from "three";
import { settings } from "../helpers/settings";
// COMPONENT
const Track = (map) => {
  // STATE
  const { height, width } = map;
  const { colors } = settings;
  const {
    radius,
    innerRadius,
    outerRadius,
    arcAngle1,
    arcAngle2,
    arcCenter,
    arcAngle3,
    arcAngle4,
  } = settings.track;
  // PLANE TEXTURE
  const planeTexture = () => {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext("2d");
    context.fillStyle = colors.track.road;
    context.fillRect(0, 0, width, height);
    context.lineWidth = 2;
    context.strokeStyle = colors.track.dash;
    context.setLineDash([10, 14]);
    context.beginPath();
    context.arc(width / 2 - arcCenter, height / 2, radius, 0, Math.PI * 2);
    context.stroke();
    context.beginPath();
    context.arc(width / 2 + arcCenter, height / 2, radius, 0, Math.PI * 2);
    context.stroke();
    return new THREE.CanvasTexture(canvas);
  };
  // FIELD TEXTURE
  const fieldTexture = () => {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext("2d");
    context.fillStyle = colors.track.lawn;
    context.fillRect(0, 0, width, height);
    context.lineWidth = 65;
    context.strokeStyle = colors.track.lawnHighlight;
    context.beginPath();
    context.arc(
      width / 2 - arcCenter,
      height / 2,
      innerRadius,
      arcAngle1,
      -arcAngle1
    );
    context.arc(
      width / 2 + arcCenter,
      height / 2,
      outerRadius,
      Math.PI + arcAngle2,
      Math.PI - arcAngle2,
      true
    );
    context.stroke();
    context.beginPath();
    context.arc(
      width / 2 + arcCenter,
      height / 2,
      innerRadius,
      Math.PI + arcAngle1,
      Math.PI - arcAngle1
    );
    context.arc(
      width / 2 - arcCenter,
      height / 2,
      outerRadius,
      arcAngle2,
      -arcAngle2,
      true
    );
    context.stroke();
    context.lineWidth = 60;
    context.strokeStyle = colors.track.lawn;
    context.beginPath();
    context.arc(
      width / 2 - arcCenter,
      height / 2,
      innerRadius,
      arcAngle1,
      -arcAngle1
    );
    context.arc(
      width / 2 + arcCenter,
      height / 2,
      outerRadius,
      Math.PI + arcAngle2,
      Math.PI - arcAngle2,
      true
    );
    context.arc(
      width / 2 + arcCenter,
      height / 2,
      innerRadius,
      Math.PI + arcAngle1,
      Math.PI - arcAngle1
    );
    context.arc(
      width / 2 - arcCenter,
      height / 2,
      outerRadius,
      arcAngle2,
      -arcAngle2,
      true
    );
    context.stroke();
    context.lineWidth = 6;
    context.strokeStyle = colors.track.edge;
    context.beginPath();
    context.arc(width / 2 - arcCenter, height / 2, outerRadius, 0, Math.PI * 2);
    context.stroke();
    context.beginPath();
    context.arc(width / 2 + arcCenter, height / 2, outerRadius, 0, Math.PI * 2);
    context.stroke();
    context.beginPath();
    context.arc(width / 2 - arcCenter, height / 2, innerRadius, 0, Math.PI * 2);
    context.stroke();
    context.beginPath();
    context.arc(width / 2 + arcCenter, height / 2, innerRadius, 0, Math.PI * 2);
    context.stroke();
    const texture = new THREE.CanvasTexture(canvas);
    texture.offset = new THREE.Vector2(0.5, 0.5);
    texture.repeat.set(1 / width, 1 / height);
    return texture;
  };
  // LEFT ISLAND
  const leftIsland = () => {
    const shape = new THREE.Shape();
    shape.absarc(-arcCenter, 0, innerRadius, arcAngle1, -arcAngle1, false);
    shape.absarc(
      arcCenter,
      0,
      outerRadius,
      Math.PI + arcAngle2,
      Math.PI - arcAngle2,
      true
    );
    return shape;
  };
  // MIDDLE ISLAND
  const middleIsland = () => {
    const shape = new THREE.Shape();
    shape.absarc(-arcCenter, 0, innerRadius, arcAngle3, -arcAngle3, true);
    shape.absarc(
      arcCenter,
      0,
      innerRadius,
      Math.PI + arcAngle3,
      Math.PI - arcAngle3,
      true
    );
    return shape;
  };
  // RIGHT ISLAND
  const rightIsland = () => {
    const shape = new THREE.Shape();
    shape.absarc(
      arcCenter,
      0,
      innerRadius,
      Math.PI - arcAngle1,
      Math.PI + arcAngle1,
      true
    );
    shape.absarc(-arcCenter, 0, outerRadius, -arcAngle2, arcAngle2, false);
    return shape;
  };
  // OUTTER FIELD
  const outterField = () => {
    const shape = new THREE.Shape();
    shape.moveTo(-width / 2, -height / 2);
    shape.lineTo(0, -height / 2);
    shape.absarc(-arcCenter, 0, outerRadius, -arcAngle4, arcAngle4, true);
    shape.absarc(
      arcCenter,
      0,
      outerRadius,
      Math.PI - arcAngle4,
      Math.PI + arcAngle4,
      true
    );
    shape.lineTo(0, -height / 2);
    shape.lineTo(width / 2, -height / 2);
    shape.lineTo(width / 2, height / 2);
    shape.lineTo(-width / 2, height / 2);
    return shape;
  };
  // PLANE
  const plane = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(width, height),
    new THREE.MeshLambertMaterial({
      map: planeTexture(),
    })
  );
  plane.receiveShadow = true;
  plane.matrixAutoUpdate = false;
  // FIELD
  const field = new THREE.Mesh(
    new THREE.ExtrudeBufferGeometry(
      [leftIsland(), rightIsland(), middleIsland(), outterField()],
      { depth: 6, bevelEnabled: false }
    ),
    [
      new THREE.MeshLambertMaterial({ map: fieldTexture() }),
      new THREE.MeshLambertMaterial({ color: settings.colors.track.lawn }),
    ]
  );
  field.receiveShadow = true;
  field.matrixAutoUpdate = false;
  // RENDER
  return { plane, field };
};
// EXPORT
export default Track;
