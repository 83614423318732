// IMPORTS
import * as THREE from "three";
import { randomValue } from "../helpers/methods";
import { settings } from "../helpers/settings";
import Wheel from "./Wheel";
// COMPONENT
const Car = (isPlayer) => {
  // COLORS
  const {
    player,
    vehicle,
    other: {
      cab,
      window: { outter, inner },
    },
  } = settings.colors;
  // WINDSHIELD TEXTURE
  const windShieldTexture = () => {
    const canvas = document.createElement("canvas");
    canvas.width = 64;
    canvas.height = 32;
    const context = canvas.getContext("2d");
    context.fillStyle = outter;
    context.fillRect(0, 0, 64, 32);
    context.fillStyle = inner;
    context.fillRect(8, 8, 48, 24);
    return new THREE.CanvasTexture(canvas);
  };
  // WINDOW TEXTURE
  const windowTexture = () => {
    const canvas = document.createElement("canvas");
    canvas.width = 128;
    canvas.height = 32;
    const context = canvas.getContext("2d");
    context.fillStyle = outter;
    context.fillRect(0, 0, 128, 32);
    context.fillStyle = inner;
    context.fillRect(10, 8, 38, 24);
    context.fillRect(58, 8, 60, 24);
    return new THREE.CanvasTexture(canvas);
  };
  // GENERATE BODY
  const generateBody = () => {
    const mesh = new THREE.Mesh(
      new THREE.BoxBufferGeometry(60, 30, 15),
      new THREE.MeshLambertMaterial({
        color: isPlayer ? player : randomValue(vehicle),
      })
    );
    mesh.position.z = 12;
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    return mesh;
  };
  // GENERATE CAB
  const generateCab = () => {
    const carFrontTexture = windShieldTexture();
    carFrontTexture.center = new THREE.Vector2(0.5, 0.5);
    carFrontTexture.rotation = Math.PI / 2;
    const carBackTexture = windShieldTexture();
    carBackTexture.center = new THREE.Vector2(0.5, 0.5);
    carBackTexture.rotation = -Math.PI / 2;
    const carLeftSideTexture = windowTexture();
    carLeftSideTexture.flipY = false;
    const carRightSideTexture = windowTexture();
    const mesh = new THREE.Mesh(new THREE.BoxBufferGeometry(33, 24, 12), [
      new THREE.MeshLambertMaterial({ map: carFrontTexture }),
      new THREE.MeshLambertMaterial({ map: carBackTexture }),
      new THREE.MeshLambertMaterial({ map: carLeftSideTexture }),
      new THREE.MeshLambertMaterial({ map: carRightSideTexture }),
      new THREE.MeshLambertMaterial({ color: cab }),
      new THREE.MeshLambertMaterial({ color: cab }),
    ]);
    mesh.position.x = -6;
    mesh.position.z = 25.5;
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    return mesh;
  };
  // GENERATE WHEELS
  const generateWheels = () => {
    const frontWheel = Wheel();
    frontWheel.position.x = 18;
    const backWheel = Wheel();
    backWheel.position.x = -18;
    return { frontWheel, backWheel };
  };
  // RENDER
  const car = new THREE.Group();
  const { frontWheel, backWheel } = generateWheels();
  car.add(generateBody());
  car.add(generateCab());
  car.add(frontWheel);
  car.add(backWheel);
  return car;
};
// EXPORT
export default Car;
