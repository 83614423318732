// RANDOM SPEED
const randomSpeed = (minSpeed, maxSpeed) => {
  return minSpeed + Math.random() * (maxSpeed - minSpeed);
}
// RANDOM VALUE
const randomValue = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};
// GET DISTANCE
const getDistance = (coordinate1, coordinate2) => {
  const horizontalDistance = coordinate2.x - coordinate1.x;
  const verticalDistance = coordinate2.y - coordinate1.y;
  return Math.sqrt(horizontalDistance ** 2 + verticalDistance ** 2);
};
// EXPORT
export { randomSpeed, randomValue, getDistance };
