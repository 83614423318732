// COLORS
const colors = {
  player: 0xa52523,
  vehicle: [0x0ad3ff, 0xff9f1c, 0xbdb638, 0x78b14b],
  other: {
    cab: 0xffffff,
    chasis: 0xb4c6fc,
    wheel: 0x333333,
    window: {
      outter: "#ffffff",
      inner: "#666666",
    },
  },
  track: {
    lawn: "#67C240",
    lawnHighlight: "#A2FF75",
    road: "#546E90",
    dash: "#E0FFFF",
    edge: "#725F48",
  },
  tree: {
    crown: 0x498c2c,
    trunk: 0x4b3f2f,
  },
};
// TRACK
const track_settings = () => {
  const radius = 225;
  const trackWidth = 45;
  const innerRadius = radius - trackWidth;
  const outerRadius = radius + trackWidth;
  const arcAngle1 = (1 / 3) * Math.PI;
  const deltaY = Math.sin(arcAngle1) * innerRadius;
  const arcAngle2 = Math.asin(deltaY / outerRadius);
  const arcCenter =
    (Math.cos(arcAngle1) * innerRadius + Math.cos(arcAngle2) * outerRadius) / 2;
  const arcAngle3 = Math.acos(arcCenter / innerRadius);
  const arcAngle4 = Math.acos(arcCenter / outerRadius);
  return {
    radius,
    trackWidth,
    innerRadius,
    outerRadius,
    arcAngle1,
    deltaY,
    arcAngle2,
    arcCenter,
    arcAngle3,
    arcAngle4,
  };
};
// SETTINGS
export const settings = {
  colors,
  track: track_settings(),
  showHitZones: false,
};
