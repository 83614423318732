// IMPORTS
import "./styles/App.scss";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import Game from "./pages/Game";
import Menu from "./components/Menu";
// COMPONENT
const App = () => {
  // STATE
  const [appState, setAppState] = useState({
    game: {
      started: false,
      paused: false,
      over: false,
      score: 0,
    },
  });
  const { started, paused, over } = appState.game;
  // RENDER
  return (
    <div className="appContainer">
      <Game appRef={{ appState, setAppState }} />
      {(!started || paused || over) && (
        <Menu type="app" gameState={appState.game} />
      )}
    </div>
  );
};
// INITIALIZE
ReactDOM.render(<App />, document.getElementById("root"));
